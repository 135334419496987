@font-face {
  font-family: "Panton";
  src: url('../fonts/Panton/Panton-BlackCaps.otf');
}

@font-face {
  font-family: "Nunito";
  src: url('../fonts/Nunito/Nunito-VariableFont_wght.ttf');
}

@font-face {
  font-family: "Nexa Demo";
  /* src: url('/fonts/Nexa/NexaTextDemo-Bold.woff2'); */
  src: url('../fonts/Nexa/NexaBold.otf');
}

.App {
  text-align: center;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 500px) {
  .mobile-skeleton-image {
    width: calc(100vw - 130px);
  }
}

.styled-floated-button .css-dev-only-do-not-override-z1mxze.ant-float-btn.ant-float-btn-default.ant-float-btn-circle {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.styled-floated-button .css-dev-only-do-not-override-z1mxze.ant-float-btn.ant-float-btn-default.ant-float-btn-circle .ant-float-btn-body {
  width: 30px;
  height: 30px;
}

.styled-floated-button .css-dev-only-do-not-override-z1mxze.ant-float-btn-group-wrap {
  margin-bottom: 10px;
}

.layout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff85;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contract-collapse .ant-collapse-header.ant-collapse-header-collapsible-only {
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.dealer-artwork-cards .css-dev-only-do-not-override-z1mxze.ant-empty .ant-empty-description {
  color: #b3b5b8;
}